import React from 'react';

import {
    section as sectionClass,
    grid,
    title as titleClass,
    list,
    item,
    icon,
    itemTitle,
    itemDescription,
} from './user-rent.module.scss';

import CheckIcon from '../../assets/images/svg/circle-check.svg';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';

interface IUserRentProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function UserRent({ className, TitleTag, section }: IUserRentProps) {
    const { sectionId, content, css, style } = section;
    const { texts } = content;
    const [
        title,
        ,
        ,
        title1,
        title2,
        title3,
        title4,
        description1,
        description2,
        description3,
        description4,
    ] = texts;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionClass}`}
            classes={{
                container: grid,
                title: titleClass,
            }}
            TitleTag={TitleTag}
            title={title}
            css={css}
            style={style}
        >
            <ul className={list}>
                {title1 && (
                    <li className={item}>
                        <CheckIcon className={icon} />
                        <div>
                            <p className={itemTitle}>{title1}</p>
                            {description1 && (
                                <Markdown className={itemDescription}>{description1}</Markdown>
                            )}
                        </div>
                    </li>
                )}
                {title2 && (
                    <li className={item}>
                        <CheckIcon className={icon} />
                        <div>
                            <p className={itemTitle}>{title2}</p>
                            {description2 && (
                                <Markdown className={itemDescription}>{description2}</Markdown>
                            )}
                        </div>
                    </li>
                )}
                {title3 && (
                    <li className={item}>
                        <CheckIcon className={icon} />
                        <div>
                            <p className={itemTitle}>{title3}</p>
                            {description3 && (
                                <Markdown className={itemDescription}>{description3}</Markdown>
                            )}
                        </div>
                    </li>
                )}
                {title4 && (
                    <li className={item}>
                        <CheckIcon className={icon} />
                        <div>
                            <p className={itemTitle}>{title4}</p>
                            {description4 && (
                                <Markdown className={itemDescription}>{description4}</Markdown>
                            )}
                        </div>
                    </li>
                )}
            </ul>
        </Section>
    );
}
