import React from "react";
import {Image} from "@alterpage/gatsby-plugin-image";

import {
    grid,
    titleText,
    contentContainer,
    columnContainer,
    columnDescription,
    iconColumn
} from './icon-columns.module.scss';

import {ISection} from "../../models/section.model";

import Section from "../hoc/section";
import Markdown from "../hoc/markdown";

interface IIconColumnsProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const IconColumns:React.FC<IIconColumnsProps> = ({className, section, TitleTag}) => {
    const {
        sectionId,
        css,
        style,
        content: { texts, buttons, images },
    } = section;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
        >
            <div className={contentContainer}>
                <div className={columnContainer}>
                    <div className={iconColumn}>
                        {images && <Image media={images[0].media} />}
                        <h3>{texts[3]}</h3>
                        <Markdown className={columnDescription}>{texts[6] || ''}</Markdown>
                    </div>
                    <div className={iconColumn}>
                        {images && <Image media={images[1].media} />}
                        <h3>{texts[4]}</h3>
                        <Markdown className={columnDescription}>{texts[7] || ''}</Markdown>
                    </div>
                    <div className={iconColumn}>
                        {images && <Image media={images[2].media} />}
                        <h3>{texts[5]}</h3>
                        <Markdown className={columnDescription}>{texts[8] || ''}</Markdown>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default IconColumns;